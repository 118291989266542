import React, { useState, useEffect, useCallback } from 'react';

function ImageSlideshow({ imageUrls, width, height, isSlideshow = false }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const ensureHttps = useCallback((url) => {
    return url.replace('http://', 'https://');
  }, []);

  useEffect(() => {
    if (isSlideshow && imageUrls.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      }, 15000); 
      return () => clearInterval(interval);
    }
  }, [isSlideshow, imageUrls.length]);

  const containerStyle = {
    width: '100%',
    height: '300px',
    position: 'relative',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  };

  return (
    <div className='mt-8 flex justify-center content-center w-full' style={containerStyle}>
      {imageUrls.length > 0 && (
        <img
          className='w-full h-full'
          src={ensureHttps(imageUrls[currentIndex])}
          alt={`Slide ${currentIndex + 1}`}
          style={imageStyle}
        />
      )}
    </div>
  );
}

export default ImageSlideshow;